import { AutoColumn, Button, IconButton } from '@pancakeswap/uikit'
import { Swap as SwapUI } from '@pancakeswap/widgets-internal'
import styled from 'styled-components'
import { useCallback, memo, useState } from 'react'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { useTranslation } from '@pancakeswap/localization'

import { useExpertMode } from '@pancakeswap/utils/user'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { useSwapState } from 'state/swap/hooks'
import { Field } from 'state/swap/actions'
import { AutoRow } from 'components/Layout/Row'

import { useAllowRecipient } from '../hooks'

const StyledButton = styled(IconButton)`
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    opacity: 0.7;
  }
`

const SwitchIcon: React.FC = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.99985 1.99991V9.99991H2.41385C2.2161 9.99995 2.0228 10.0586 1.85839 10.1685C1.69398 10.2784 1.56584 10.4346 1.49017 10.6173C1.4145 10.8 1.3947 11.001 1.43327 11.1949C1.47183 11.3889 1.56704 11.5671 1.70685 11.7069L8.29285 18.2929C8.48038 18.4804 8.73469 18.5857 8.99985 18.5857C9.26501 18.5857 9.51932 18.4804 9.70685 18.2929L16.2928 11.7069C16.4327 11.5671 16.5279 11.3889 16.5664 11.1949C16.605 11.001 16.5852 10.8 16.5095 10.6173C16.4339 10.4346 16.3057 10.2784 16.1413 10.1685C15.9769 10.0586 15.7836 9.99995 15.5858 9.99991H11.9998V1.99991C11.9998 1.73469 11.8945 1.48034 11.707 1.2928C11.5194 1.10526 11.2651 0.999907 10.9998 0.999907H6.99985C6.73463 0.999907 6.48028 1.10526 6.29274 1.2928C6.10521 1.48034 5.99985 1.73469 5.99985 1.99991Z" stroke="#B7ADD1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

export const FlipButton = memo(function FlipButton() {
  const { t } = useTranslation()
  const [isExpertMode] = useExpertMode()
  const { onSwitchTokens, onChangeRecipient } = useSwapActionHandlers()
  const {
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const allowRecipient = useAllowRecipient()

  const onFlip = useCallback(() => {
    onSwitchTokens()
    replaceBrowserHistory('inputCurrency', outputCurrencyId)
    replaceBrowserHistory('outputCurrency', inputCurrencyId)
  }, [onSwitchTokens, inputCurrencyId, outputCurrencyId])

  return (
    <AutoColumn justify="space-between">
      <AutoRow justify={isExpertMode ? 'space-between' : 'center'} nowrap>
        <StyledButton onClick={onFlip}>
          <SwitchIcon />
        </StyledButton>
        {allowRecipient && recipient === null ? (
          <Button variant="text" id="add-recipient-button" onClick={() => onChangeRecipient('')} style={{ height: 0, fontSize: '16px' }}>
            + {t('Add a recipient (optional)')}
          </Button>
        ) : null}
      </AutoRow>
    </AutoColumn>
  )
})
