import { Box, Flex } from '@pancakeswap/uikit'
import { styled } from 'styled-components'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;
  // padding: 0 16px;
  width: calc(100vw - 2em);

  border-radius: 25px;
  max-width: 436px;
  padding: 1em;
  // @media (max-width: 767px) {
  //   & {
  //   }
  // }
  
  z-index: 1;
  border: 1px solid #403d3f;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #180110 0%, #160110 50%, #140110 100%);
  box-shadow: #fff 0px 2px 20px;

  // ${({ theme }) => theme.mediaQueries.lg} {
  //   padding: 1em 16px;
  // }

  // ${({ theme }) => theme.mediaQueries.xxl} {
  //   ${({ $isChartExpanded }) => ($isChartExpanded ? 'padding: 1em 120px' : 'padding: 1em 16px')};
  // }
`

export const StyledInputCurrencyWrapper = styled(Box)`
  width: 100%;
`
